import Logo from './beyondpaperback.png';
export const SERVER_URL = "https://www.beyondpaperback.com/";
export const APP_NAME="BeyondPaperback";
export const LOGO=Logo;
export const FbLoginID=3810274889244071;
export const AppleClientId="co.novareader.researchpad";
export const GmailClientId="675396734183-uue07m9hl0vf50adqiccil7hoouap66e.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.beyondpaperback.com/login"
export const URL="https://mobile.beyondpaperback.com/";
export const AppVersion="6";
export const RedirectUri="https://www.beyondpaperback.com/applelogin"
export const Cover="https://storage.googleapis.com/nova-epitome-publishing-unsecured/unsecured/";